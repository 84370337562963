
import qs from 'query-string'
import { computed, defineComponent, onMounted, ref, watch, getCurrentInstance } from 'vue'
import api from '@/api/api'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
import useRouter from '@/hooks/useRouter'
import { useModalStore } from '@/store/stores/modal'
import { ImportantError } from '@/helpers/Error'
import ClientForm from '@/components/clientForm/ClientForm.vue'
export default defineComponent({
    components: { SpinnerLoader, ClientForm },
    setup(props, {}) {
        const root = getCurrentInstance()!.proxy
        const loading = ref(true)
        const { route, router } = useRouter(root)
        const enableSms = ref(false)
        const showClientForm = ref()
        const modalStore = useModalStore()
        const showClientFormModal = computed(() => !loading.value && showClientForm.value)
        onMounted(async () => {
            loading.value = true
            showClientForm.value = await api.clientForm.getClientFormExists().then(res => !res.data?.value)
            loading.value = false
        })
        const onAnswersSaved = () => {
            showClientForm.value = false
        }
        const clientFormExtra = computed(() => {
            return { encodedUserData: route.value.query?.userData }
        })
        const createProject = async () => {
            loading.value = true
            const query = route.value.query
            const params = route.value.params

            const res = await api.project.initFromMarketPlace({
                type: params.connectorType,
                encodedUserData: query.userData,
                userSign: query.userDataSign,
                companyId: query.companyId,
                token: query.token,
            } as any)
            if (res.error) {
                new ImportantError('Dikidi init from marketplace error', { payload: res })
                modalStore.open({ text: 'Ошибка подключения, обратитесь в техподдержку' })
            }
            console.log('res', res)
            await root.$store.dispatch('project/setProjects')
            router.replace('/projects')
        }

        watch(showClientForm, val => {
            if (!val) createProject()
        })
        return {
            loading,
            enableSms,
            createProject,
            showClientForm,
            onAnswersSaved,
            showClientFormModal,
            clientFormExtra,
        }
    },
})
